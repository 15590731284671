.navigation

  &--trigger

    &-deco-01,
    &-deco-02,
    &-deco-03,
    &-deco-04
      @apply content-['']
      @apply block w-6 md:w-7 lg:w-8 h-px md:h-0.5
      @apply absolute top-1/2 left-1/2 -translate-x-[55%]
      @apply transition-all duration-200 origin-center
      @apply bg-current

    &-deco-01
      @apply rotate-0
      @apply -translate-y-1.5 md:-translate-y-2 lg:-translate-y-2.5
    &-deco-02
      @apply rotate-0
      @apply translate-y-1.5 md:translate-y-2 lg:translate-y-2.5
    &-deco-03
      @apply rotate-0
    &-deco-04
      @apply rotate-0

    .navigation__active &
      &-deco-01
        @apply opacity-0 -translate-y-4
      &-deco-02
        @apply opacity-0 translate-y-4
      &-deco-03
        @apply rotate-45
      &-deco-04
        @apply -rotate-45
