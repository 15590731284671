@import '../../site/plugins/kirby3-cookie-banner/dist/assets/cookie-banner-DGJAdqxF.css';

@import 'fonts';
@import 'properties';

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@import 'accordion';
@import 'base';
@import 'cookie-modal';
@import 'editor';
@import 'footer';
// @import 'form';
@import 'header';
// @import 'hero';
@import 'img';
// @import 'linkteaser';
@import 'navigation';
@import 'reveal';
// @import 'slider';
@import 'video';

// @import "./vendor/tiny-slider.css";
