#cookie-modal
  @apply static

  .cookie-modal__content
    @apply fixed z-[120] bottom-0 right-0 border-t border-t-theme-green-100
    @apply rounded-none bg-theme-green-700 text-theme-white
    @apply w-full max-w-none
    @apply px-0 pb-0 m-0 shadow-none
    @apply py-4 md:py-8 pt-6 md:pt-8 lg:py-12

  .cookie-modal__title
    @apply font-normal text-xl lg:text-2xl 2xl:text-4xl
    @apply mb-4 md:mb-6

  .cookie-modal__text
    // @apply text-cookie_text_mobile md:text-cookie_text_tablet lg:text-cookie_text_desktop
    @apply mb-4 md:mb-6
    span
      @apply block mb-[0.75em]

  .cookie-modal__options
    @apply flex flex-col gap-4
    @apply mb-0

  .cookie-modal__option
    @apply block mr-0 w-full border-none p-0 px-0 select-none
    @apply pr-12 md:pr-20 relative

    &.disabled
      @apply opacity-100
      .cookie-modal__checkbox
        @apply cursor-not-allowed

    .cookie-modal__checkbox,
    .cookie-modal__check
      @apply absolute left-auto right-0 top-1/2 -translate-y-1/2  rounded-full overflow-hidden
      @apply w-14 h-8 m-0
    .cookie-modal__checkbox
      @apply opacity-0 z-20
    .cookie-modal__check
      @apply border z-10
      // @apply bg-gradient-to-r from-orange_l via-orange_l to-50% from-50% to-black
      // background: linear-gradient( to right, var(--color-orange), var(--color-orange) 50%, var(--color-neutral) 50%, var(--color-neutral) )

      &:after
        content: ''
        @apply size-5 bg-theme-white absolute left-1 transition-all rounded-full
    .cookie-modal__checkbox:checked ~ .cookie-modal__check
      // @apply bg-orange_l
      &:after
        @apply left-auto right-1


  .cookie-modal__label
    @apply font-normal text-base md:text-lg 2xl:text-xl
    @apply block translate-y-[3px] md:translate-y-1

  .cookie-modal__buttons
    @apply flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4 mt-4 md:mt-8
    // @apply border-theme-orange border-t

  .cookie-modal__button
    @apply mx-0
    &.hide
      @apply hidden

  .cookie-modal__label
    @apply cursor-none
