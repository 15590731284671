.reveal
  @apply opacity-0 transition-opacity duration-1000 ease-in-out
  &.reveal__show,
  .reveal__show &
    @apply opacity-100

  &.reveal-up
    @apply translate-y-8 md:translate-y-16 transition-[opacity,transform] duration-1000 ease-in-out
    &.reveal__show,
    .reveal__show &
      @apply translate-y-0 md:translate-y-0
