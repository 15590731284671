.header

  @apply py-3 md:py-4 transition-[padding] duration-300
  .header-logo
    @apply w-[70%] md:w-64 lg:w-[380px] transition-[width] duration-300

  &.has-scrolled
    @apply py-2.5 md:py-4
    .header-logo
      @apply w-40 md:w-48 lg:w-[280px]
