@layer components {
  .button {
    // @apply text-base md:text-lg 2xl:text-xl;
    @apply text-base;
    @apply block md:inline-block w-full sm:w-auto text-center;
    @apply pt-[0.5em] pb-[0.6em] px-12 whitespace-nowrap rounded-full border-none text-theme-green-700;
    @apply relative after:content-['→'] after:absolute after:left-[1em] after:top-1/2 after:-translate-y-1/2 after:text-theme-red after:transition-colors after:duration-300;
    @apply bg-theme-neutral hover:bg-theme-green-700 hover:text-theme-white hover:after:text-white transition-colors duration-300;
  }

  .button-green-100 {
    @apply bg-theme-green-100 after:text-theme-copy text-theme-copy;
  }

  .button-green-600 {
    @apply bg-theme-green-700 after:text-theme-white text-theme-white hover:bg-theme-green-400;
  }

  .button-green-700 {
    // @apply after:size-6 after:rounded-full after:bg-theme-white after:left-[0.5em];
    @apply bg-theme-green-700 text-theme-white hover:bg-theme-green-400 hover:after:text-theme-red;
  }

  .button-white {
    @apply bg-white text-theme-green-700;
    @apply after:text-theme-red;
    @apply hover:bg-theme-green-100 hover:text-theme-green-700 hover:after:text-theme-green-700;
  }
}
