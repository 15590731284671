@layer utilities {
  .editor {
    p {
      @apply mb-[1em];
    }
    a {
      @apply hover:underline;
    }
  }
}
