.editor
  @apply text-base md:text-lg 2xl:text-xl
  .footer &
    @apply text-base md:text-lg 2xl:text-lg

  strong
    @apply font-semibold

  a
    @apply underline underline-offset-2 lg:underline-offset-4 decoration-current
    @apply hover:text-theme-green-700 transition-colors
    .bg-theme-green-700 &,
    .background-dark &
      @apply decoration-theme-green-100 hover:text-theme-white

  p
    @apply mb-[1.5em] last:mb-0

  p + ul,
  p + ol
    @apply -mt-[1em]

  ul
    @apply mb-[1.5em] last:mb-0
    li
      @apply pl-5 md:pl-7 mb-[0.25em]
      @apply relative before:content-['→'] before:absolute before:left-0

      p
        @apply mb-0

  ol
    @apply list-none
    @apply mb-[1.5em] ml-[1em] last:mb-0
    @apply list-decimal
    li
      @apply pl-[0.3em] mb-[0.25em]
      @apply relative mb-0 marker:block marker:left-0
      &::marker
        position: absolute


  h2
    @apply text-xl lg:text-2xl 2xl:text-3xl leading-tight
    @apply text-theme-green-700
    @apply mb-[0.25em] last:mb-0
  h3
    @apply text-lg lg:text-xl 2xl:text-2xl leading-tight
    @apply text-theme-green-700
    @apply mb-[0.25em] last:mb-0

  p + h2,
  p + h3,
  ul + h2,
  ul + h3
    @apply mt-[1.75em]
