@font-face {
  font-family: "tt-commons-pro";
  src: url("/assets/fonts/TT_Commons_Pro_Medium.woff2") format("woff2"), url("/assets/fonts/TT_Commons_Pro_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "tt-commons-pro";
  src: url("/assets/fonts/TT_Commons_Pro_Bold.woff2") format("woff2"), url("/assets/fonts/TT_Commons_Pro_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
